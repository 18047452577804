import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer_container">
                    <div className="copyright">&copy; Copyright 2023 by View4View. All rights reserved.</div>
                    <div className="footer_menu">
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/legals">Legals</Link>
                        <a href="https://discord.gg/h39rPc6WQx" target="_blank">Support Server</a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;