import Footer from "../layout/footer";
import Nav from "../layout/nav";
import Seo from "../utils/Seo";

const Privacy_Policy = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <body>
                <div className="about">
                    <article>
                        <h1>PRIVACY POLICY | GOOGLE API | LAST MODIFICATION : 16/05/2023</h1>
                        <br />
                        <h4>What informations do we collect from customers and visitors?</h4>
                        <p>We are collecting these informations: Your youtube channel (id, image, name). By using our service you must agree to Google Analytics and Google terms of use and privacy policy.</p>
                        <h4>How do we secure and protect the collected data?</h4>
                        <p>We use alternative service to store your data. For your username we use Google. In our database we store the minimum informations
                            about you. (Youtube channel informations)</p>
                        <h4>How can i delete my informations?</h4>
                        <p>If you want to delete or change your informations, just contact us via our <a href="https://discord.gg/h39rPc6WQx">Discord Server</a>.</p>
                        <br />
                        <h4>Google Api</h4>
                        <p>We follow the Google API Services User Data Policy including the Limited Use requirements</p>
                        <p>Url of the Google API Services User Data Policy <a href="https://developers.google.com/terms/api-services-user-data-policy">click here</a></p>
                        <h4>Data Protection and Encryption:</h4>
                        <p>We employ strict measures to ensure that your data is protected and secure.
                            We utilize industry-standard encryption techniques to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction.
                            Our encryption protocols ensure that your data remains confidential and inaccessible to unauthorized parties.</p>
                        <br />
                    </article>
                </div>
            </body>
            <Footer />
        </>
    );
};

export default Privacy_Policy;