import Footer from "../layout/footer";
import Nav from "../layout/nav";
import { useState, useEffect } from "react";
import getUserHistory from "../api/methods/getUserHistory";
import Loading from "../utils/loading";
import Seo from "../utils/Seo";

const History = () => {
    const [history_user, setHistory_user] = useState([]);
    const [load, setLoad] = useState(undefined);

    const getUserHistory_ = async () => {
        const user_history = await getUserHistory();
        if (user_history && user_history.success === true) {
            setHistory_user(user_history.data);
            setLoad(false)
        }
    }

    useEffect(() => {
        getUserHistory_();
        setLoad(true);
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <body>
                <div className="container">
                    <div className="container">
                        <h1 className="titlemain">HISTORY - LOGS</h1>
                        <div className="container_tips">
                            <div className="position_tips"><i className="fa-solid fa-circle-info"></i><p>Here is display the last 50 actions you made on the website</p></div>
                        </div>
                    </div>

                    {load ? <Loading /> :
                        <>
                            <div className="container background">
                                {history_user.length > 0 ? history_user.map((transaction) => {
                                    return (
                                        <>
                                            <p class="p_history">{transaction.history}</p>
                                        </>
                                    )
                                }) : <h1>You don't have any transactions.</h1>}
                            </div>
                        </>
                    }
                </div>
            </body>
            <Footer />
        </>
    );
};

export default History;