import Footer from "../layout/footer";
import Nav from "../layout/nav";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../utils/loading";
import coinsEffect from "../utils/coinsEffect";
import Seo from "../utils/Seo";
import getViewData from "../api/methods/getViewData";
import getCampaign from "../api/methods/getCampaign";
import YouTube from 'react-youtube';
import skipVideo from "../api/methods/skipVideo";
import getStatut from "../api/methods/getStatut";

const VideoPlayer = ({ videoId }) => {
    const [key, setKey] = useState(0);
    const playerRef = useRef(null);
    const timerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        setKey((prevKey) => prevKey + 1);
        resetTimer();

        return () => {
            resetTimer();
        };
    }, [videoId]);

    const resetTimer = () => {
        clearInterval(timerRef.current);
        timerRef.current = null;
    };

    const onReady = (event) => {
        event.target.playVideo();
    };

    const onPlay = async () => {
        resetTimer();

        timerRef.current = setInterval(async () => {
            const request = await getViewData(videoId, undefined);
            if (request && request.success === true) {
                if (request.data === undefined) {
                }
            }
        }, 10000);

        const request = await getViewData(videoId, "play");
        if (request && request.success === true) {
            console.log(request.data);
        }
    };

    const onPause = async () => {
        console.log("pause");
        await getViewData(videoId, "pause");
    };

    const onEnd = async (event) => {
        await getViewData(videoId, undefined);
    };

    const onError = async (event) => {
        console.log("ERROR " + videoId)
        const request = await skipVideo(videoId);
        if (request && request.success === true) {
            window.location.reload();
        }
    };

    return (
        <YouTube
            key={key}
            videoId={videoId}
            onReady={onReady}
            onPlay={onPlay}
            onPause={onPause}
            onEnd={onEnd}
            onError={onError}
            ref={playerRef}
        />
    );
};



const Discover = () => {
    const [load, setLoad] = useState(undefined);
    const [autofarm, setAutoFarm] = useState(false);
    const [campaign, setCampaign] = useState(undefined);
    const [watchtime, setWatchTime] = useState(undefined);
    const [type, setType] = useState(undefined);
    const [duration, setDuration] = useState(undefined);

    const addCampaign = async () => {
        const request = await getCampaign();
        if (request && request.success === true) {
            setCampaign(request.data.id)
            setWatchTime(request.data.watchtime);
            setDuration(request.data.duration);
            setType(request.data.type);
            setLoad(false);
        } else {
            setLoad(false);
        }
    }

    const skip = async () => {
        const request = await skipVideo(campaign);
        if (request && request.success === true) {
            setLoad(true);
            const request_ = await getCampaign();
            if (request_ && request_.success === true) {
                setCampaign(request_.data.id)
                setWatchTime(request_.data.watchtime);
                setDuration(request_.data.duration);
                setType(request_.data.type);
                setLoad(false);
            } else {
                setCampaign(undefined)
                setLoad(false)
            }
        }
    }

    const statut = async (videoId, autoFarm) => {
        const intervalId = setInterval(async () => {
            const request = await getStatut(videoId)
            if (request && request.success === true) {
                if (request.statut === "yes" && autoFarm === true) {
                    clearInterval(intervalId);
                    skip();
                }
            }
        }, 5000);
    }

    useEffect(() => {
        setInterval(() => {
            coinsEffect();
        }, 5000);

        addCampaign();
        setLoad(true);
    }, []);

    useEffect(() => {
        if (campaign && autofarm) {
            statut(campaign, autofarm);
        }
    }, [campaign, autofarm]);

    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <body>
                <div className="container">
                    <div className="container">
                        <h1 className="titlemain">DISCOVER VIDEOS</h1>
                        <div className="container_tips">
                            <div className="position_tips"><i className="fa-solid fa-circle-info"></i><p>Here is the page to earn coins! Watch videos and follow the watch time requirement to claim the rewards.</p></div>
                            <div className="position_tips"><i className="fa-solid fa-circle-info"></i><p>Do you want more coins? <Link to="/premium">Premium</Link></p></div>
                        </div>
                    </div>
                    {load ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="container background discover">
                                <h1>DISCOVER VIDEOS</h1>
                                {campaign ? (
                                    <>
                                        <span className="rewards">
                                            Reward: {watchtime ? (Number(watchtime) * 0.75).toFixed(2) : undefined} coin{watchtime !== 1 ? "s" : undefined} | {type && type === "short" ? "Watch time required: Full video (short)" : `Watch time required: ${duration && duration >= 60 ? `${watchtime ? watchtime : ""} minute${watchtime !== 1 ? "s" : ""}` : "Full video (less than 1 minute)"}`}</span>
                                        <button className="button button4" onClick={() => setAutoFarm(!autofarm)}>Watch Automatically videos ({autofarm ? "enable" : "disable"})</button>
                                        <button className="button button3" onClick={() => { skip() }}>Watch Another Video</button>
                                        <br />
                                        <div className="video_list">
                                            <VideoPlayer videoId={campaign} />
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <h1>No videos available.</h1>
                                        <Link to={"/premium"}>
                                            <button className="button button1">
                                                Purchase coins here
                                            </button>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                </div >
            </body >
            <Footer />
        </>
    );
};

export default Discover;