import request from '../request'

/**
 * Get daily rewards
**/

async function getDailyRewards() {
    return request('/view4view/me/daily')
}

export default getDailyRewards