import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import getDailyRewards from "../api/methods/getDailyRewards";
import getUserData from "../api/methods/getUserData";
import login from "../api/methods/login";
import me from "../api/methods/me";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import parsems from 'parse-ms'
import coinsEffect from "../utils/coinsEffect";

const MySwal = withReactContent(Swal)

const login_user = async (code, type) => {
    const res = await login(code, type);
    if (res && res.success === true) {
        window.localStorage.setItem("token", res.token);
        window.location.replace("/campaign");
    } else {
        window.location.replace("/login");
    }
}

const callback = () => {
    const callback_ = window.localStorage.getItem("callback")
    if (callback_ && callback_ !== null && callback_ !== "null") {
        window.location.replace(callback_);
        window.localStorage.removeItem("callback")
    }
}

const cookie = () => {
    const cookieContainer = document.querySelector(".cookie-container");
    if (cookieContainer && cookieContainer.classList) {
        cookieContainer.classList.remove("active");
        localStorage.setItem("cookieBannerDisplayed", "true");
    }
}

setTimeout(() => {
    if (!localStorage.getItem("cookieBannerDisplayed")) {
        const cookieContainer = document.querySelector(".cookie-container");
        if (cookieContainer && cookieContainer.classList) {
            cookieContainer.classList.add("active");
        }
    }
}, 2000);

if (window.location.pathname === "/code") {
    if (window.location.search.split("=") && window.location.search.split("=")[1]) {
        login_user(window.location.search.split("=")[1], "google");
    }
}

if (window.location.pathname === "/login") {
    if (window.location.search.split("=") && window.location.search.split("=")[1]) {
        login_user(window.location.search.split("=")[1], "discord");
    }
}

if (window.location.pathname === "/") {
    const affiliation = window.location.search && window.location.search.split("=").length === 2 ? window.location.search.split("=")[1] : null;
    window.localStorage.setItem("affiliation", affiliation);
}

if (window.location.pathname === "/callback") {
    callback();
}

const logout = () => {
    window.localStorage.removeItem("token")
    window.location.replace("/");
}

const removeOverflow = () => {
    document.querySelector("html").classList.remove('overflow');
}

const menuToggle = () => {
    document.querySelector('.burger').classList.toggle('menu-opened');
    var buger = document.querySelector('.burger');
    if (buger && buger.classList.contains("menu-opened") === true) {
        document.querySelector("html").classList.add('overflow');
    } else {
        document.querySelector("html").classList.remove('overflow');
    }

    document.querySelector('.navbar').classList.toggle("opened")
    var dd_main = document.querySelector(".dd_main");
    if (dd_main && dd_main.classList.contains("active") === true) {
        document.querySelector(".dd_main").classList.toggle("active");
    }
}


const manageNav = () => {
    document.querySelector(".dd_main").classList.toggle("active");
    document.querySelector("html").classList.remove('overflow');
    var navbar = document.querySelector(".navbar");
    if (navbar && navbar.classList.contains("opened") === true) {
        document.querySelector('.burger').classList.toggle('menu-opened');
        document.querySelector('.navbar').classList.toggle("opened")
    }
}

const Nav = () => {
    const [user_data, setUser_data] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const login = window.localStorage.getItem("token") ? true : false
    if (!login && !["/", "/legals", "/login/google", "/privacy", "/login/discord", "/login"].includes(window.location.pathname)) {
        window.location.replace("/login")
        // window.localStorage.setItem("callback", window.location.pathname);
    } else {
        callback();
    }

    const getUser_data = async () => {
        const user_data = await getUserData();
        if (user_data && user_data.success === true) {
            setUser_data(user_data.data);
        }
    }

    const getDailyRewards_ = async () => {
        const daily = await getDailyRewards();
        if (daily && daily.success === true) {
            coinsEffect();
            MySwal.fire({
                title: 'Daily Rewards',
                html: <>
                    <p>You claimed your {daily.coins} coins daily rewards!</p>
                    <br />
                    <p>Come back in 24 hours</p>
                </>,
                footer: '<a href="https://discord.gg/h39rPc6WQx">Support Server</a>',
                timer: 3000,
                icon: "success"
            })
        } else {
            if (daily.data && daily.data.id) {
                const time_left = parsems(86400000 - (Date.now() - daily.data.daily));
                MySwal.fire({
                    title: 'Daily Rewards',
                    html: <>
                        <p>You already claimed your daily rewards!</p>
                        <br />
                        <p>Come back in <strong>{time_left.hours}h {time_left.minutes}m {time_left.seconds}s</strong></p>
                    </>,
                    footer: '<a href="https://discord.gg/h39rPc6WQx">Support Server</a>',
                    timer: 3000,
                    icon: "error"
                })
            } else {
                MySwal.fire({
                    title: 'Daily Rewards',
                    html: <>
                        <p>An error appear. Please try again</p>
                    </>,
                    footer: '<a href="https://discord.gg/h39rPc6WQx">Support Server</a>',
                    timer: 3000,
                    icon: "error"
                })
            }
        }
    }

    const getUser = async () => {
        const user_data = await me();
        if (user_data && user_data.success === true) {
            setUser(user_data.data);
        } else {
            if (["/", "/legals", "/login/google", "/privacy", "/login/discord", "/login"].includes(window.location.pathname)) return;
            window.location.replace("/login")
        }
    }

    useEffect(() => {
        getUser_data();
        getUser();
    }, []);

    return (
        <>
            {login ?
                <div className="navbar" id="navbar">
                    <div className="navContainer">
                        <div>
                            <a className="navContainer_title" href="https://view4view.xyz">
                                <span className="stylish">VIEW4VIEW</span>
                                <span className="logo">.xyz</span>
                            </a>
                        </div>
                        <div>
                            <ul className="ul">
                                <li className="liresponsive" id="liresponsive" ><Link to="/">Home</Link></li>
                                <li className="liresponsive" id="liresponsive" ><Link to="/campaign" >Campaign</Link></li>
                                <li className="liresponsive" id="liresponsive" ><Link to="/promote" >Promote</Link></li>
                                <li className="liresponsive" id="liresponsive" ><Link to="/discover">Discover</Link></li>
                                <li className="liresponsive" id="liresponsive"><Link to="/premium" style={{ color: "rgb(106 129 255)" }}>Premium</Link></li>
                            </ul>
                        </div>
                        <div>
                            <ul className="ul">
                                <div>
                                    <Link title="View your history" to="/history" id="coins" className="nav_display_coins">{user_data ? user_data.money : 0}</Link>
                                </div>
                                <div className="icon_nav" onClick={() => { getDailyRewards_(); }}>
                                    <i className="fa-solid fa-gift" title="Claim your free daily rewards"></i>
                                </div>
                                <div className="nav_right">
                                    <li className="nr_li dd_main" onClick={manageNav}>
                                        <img src={user ? user.avatar : undefined} />
                                        <div className="dd_menu">
                                            <div className="dd_right">
                                                <ul>
                                                    <li className="username"><a className='profil_menu'>{user ? user.username : ""}</a></li>
                                                    <li className="navdd_main_a"><a className='profil_menu' onClick={logout}>Logout</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                                <div className="burger">
                                    <button className="burger-menu-icon" id="button-menu-mobile" onClick={() => { menuToggle() }}>
                                        <svg width="34" height="34" viewBox="0 0 100 100">
                                            <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
                                            <path className="line line2" d="M 20,50 H 80"></path>
                                            <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div id="navbar-menu" className="navbar-menu">
                                    <ul class="navbar-links">
                                        <li class="navbar-item" onClick={() => { removeOverflow(); }}><Link to="/" className="navbar-link">Home</Link></li>
                                        <li class="navbar-item" onClick={() => { removeOverflow(); }}><Link to="/campaign" className="navbar-link">Campaign</Link></li>
                                        <li class="navbar-item" onClick={() => { removeOverflow(); }}><Link to="/promote" className="navbar-link">Promote</Link></li>
                                        <li class="navbar-item" onClick={() => { removeOverflow(); }}><Link to="/discover" className="navbar-link">Discover</Link></li>
                                        <li class="navbar-item" onClick={() => { removeOverflow(); }}><Link to="/premium" className="navbar-link" style={{ color: "rgb(106 129 255)" }}>Premium</Link></li>
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                :
                <div className="navbar">
                    <div className="navContainer login">
                        <div>
                            <span className="stylish">VIEW4VIEW</span>
                            <span className="logo">.xyz</span>
                        </div>
                        <ul>
                            <li><a><Link to="/">Home</Link></a></li>
                            <li><a href="/login" className="getstarted">Get Started</a></li>
                        </ul>
                    </div>
                </div>
            }

            <div class="cookie-container">
                <p>We use cookies to give you the best experience on our site. Read our <Link to="/legals">Legals mentions</Link> for further informations.</p>
                <button onClick={cookie} class="cookie-btn">Okay</button>
            </div>
        </>
    );
};

export default Nav;