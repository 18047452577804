const Error = () => {
    return (
        <>
            <p style={{ color: "white", fontStyle: "bold", fontSize: "25px" }}>
                {window.location.search.split("=")[1].replaceAll("%20", " ")}
            </p>
        </>
    );
};

export default Error;