import Footer from "../layout/footer";
import Nav from "../layout/nav";
import Logo from "../public/img/logov4v.png";
import Features_logo from "../public/img/grow_views.png"
import Seo from "../utils/Seo";

const Index = () => {
    const login = window.localStorage.getItem("token") ? true : false

    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <body>
                <div className="container">
                    <div className="container">
                        <div className="container">
                            <img className="presentation_img" src={Logo} alt="View4View Logo" />
                            <h1>The Official View4View website to promote Your Youtube Videos!</h1>
                            <h3 style={{ color: "#a4c0ff" }}>
                                A fully customizable dashboard to allow you to earn ton of views for free on your youtube videos.
                                View4View is used for thousand videos, we invite you to try it out and hope you enjoy!
                            </h3>
                            <br />
                            <p style={{ color: "rgb(248 140 104)", fontStyle: "italic", fontSize: "18px" }}>
                                You recently started a new youtube channel and you want to grow a bit more faster? More views?
                                You are in the right place. Share videos with others new youtuber and grow together as a family!
                                The fastest way to grow your youtube channel organically.
                            </p>
                        </div>
                        <div>
                            <a href="https://discord.gg/h39rPc6WQx" target="_blank"><button className="button button1">Join Us On Discord</button></a>
                            <a href={login ? "/promote" : "/login"}><button className="button button1">Promote A Video</button></a>
                        </div>
                    </div>
                    <div>
                        <img className="illustration_img" src={Features_logo} alt="Features picture" />
                        <h1 className="features_h1">Features</h1>
                        <div className="features_position">
                            <div className="features">
                                <h3>1</h3>
                                <p>Watch videos <a href={login ? "/discover" : "/login"}>here</a> to get coins</p>
                            </div>
                            <div className="features">
                                <h3>2</h3>
                                <p>Use your coins to promote your videos <a href={login ? "/promote" : "/login"}>here</a></p>
                            </div>
                            <div className="features">
                                <h3>3</h3>
                                <p>Wait, views are coming!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            <Footer />
        </>
    );
};

export default Index;