import request from '../request'

/**
 * Get information on the current user
**/

async function me() {
    return request('/youtube/me')
}

export default me