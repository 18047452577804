import request from '../request'

async function purchaseCampaign(amount, id, watchtime) {
    return request('/view4view/purchase', {
        method: 'POST',
        body: JSON.stringify({
            amount, id, watchtime
        })
    })
}

export default purchaseCampaign