import Footer from "../layout/footer";
import Nav from "../layout/nav";
import Confetti from 'react-confetti'
import { useState, useEffect } from "react";
import Loading from "../utils/loading";
import createPayement from "../api/methods/createPayement";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Seo from "../utils/Seo";

const MySwal = withReactContent(Swal)

const Premium = () => {
    const [coin, setCoin] = useState(0);
    const [load, setLoad] = useState(undefined);

    const stripeSimulation = () => {
        const premium = document.getElementById("stripesimulation");
        if (premium && premium.value) {
            if (typeof (Number(premium.value)) === "number" && Number(premium.value) !== 0 && Number(premium.value) >= 60 && Number(premium.value) <= 8000) {
                document.getElementById("stripesimulationresult").innerHTML = 'You will pay ' + parseInt(Number(premium.value) / 60).toFixed(2) + '$ to receive ' + parseInt(Number(premium.value) / 60) * 60 + ' coins'
                setCoin(parseInt(Number(premium.value) / 60) * 60);
            } else {
                document.getElementById("stripesimulationresult").innerHTML = 'You need to provide a number between 60 and 8000 coins.'
                setCoin(0);
            }
        }
    }

    const buy = async () => {
        if (!coin) {
            MySwal.fire({
                title: 'Params Missing',
                html: <>
                    <p>Please provide an amount of coins.</p>
                </>,
                footer: '<a href="https://discord.gg/h39rPc6WQx">Support Server</a>',
                timer: 3000,
                icon: "error"
            })
        } else if (coin) {
            setLoad(true);
            const link = await createPayement(coin);
            if (link && link.success === true) {
                window.location.replace(link.data)
                setLoad(false);
            }
        }
    }

    return (
        <>
            <Seo dynamic={false} />

            <Confetti style={{ position: "fixed" }} />
            <Nav />
            <body>
                <div className="container">
                    <div className="container">
                        <h1 className="titlemain">BUY COINS</h1>
                        <div className="container_tips">
                            <div className="position_tips"><i className="fa-solid fa-circle-info"></i><p>Pay coins to grow your awesome channel! 1 coin = 1 view for 1 minute and 60 coins = 1$</p></div>
                            <div className="position_tips"><i className="fa-solid fa-circle-info"></i><p>You will receive your coins without delay after the payement!</p></div>
                        </div>
                    </div>
                    {load ? <Loading /> :
                        <>
                            <div className="container background">
                                <img src="http://assets.materialup.com/uploads/943c76ff-632e-4337-90db-d158dce27821/preview.jpg" />
                                <h3>Enter number coins you want:</h3>
                                <div>
                                    <p className="premium_p" id="stripesimulationresult"></p>
                                </div>
                                <div className="position_icon">
                                    <input onInput={stripeSimulation} className="number" type="text" id="stripesimulation" placeholder="Example: 600" name='buy_number' autoComplete="off" required />
                                    <a><button onClick={buy} className="button button1">Buy</button></a>
                                </div>
                                <br />
                                <span className="premium_s">Payement powered and secured by Stripe</span>
                            </div>
                        </>
                    }
                </div>
            </body>
            <Footer />
        </>
    );
};

export default Premium;