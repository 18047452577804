import Footer from "../layout/footer";
import Nav from "../layout/nav";
import Seo from "../utils/Seo";

const Legals = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <body>
                <div className="about">
                    <article>
                        <h1>GENERAL TERMS AND CONDITIONS OF SALE,USE AND PRIVACY POLICY | LAST MODIFICATION : 15/06/2022</h1>
                        <h5>By using our services/website you agree to our GENERAL TERMS AND CONDITIONS OF SALE,USE AND PRIVACY/COOKIES POLICY</h5>
                        <br />
                        <h2>1. CONDITIONS OF USE</h2>
                        <br />
                        <h4>Account</h4>
                        <p>We reserve the right to delete, modify, or perform any action on your account if deemed necessary. By creating an account you must be 13 years or above.</p>
                        <h4>Our services</h4>
                        <p>We reserve the right to delete, modify, or perform any action on your services if deemed necessary.</p>
                        <p>If you try to abuse of bugs or if you try to duplicate coins your account'll be delete.</p>
                        <br />
                        <h2>1. CONDITIONS OF SALE</h2>
                        <br />
                        <h4>Payement Gateway</h4>
                        <p>You are not allowed to chargeback or ask the refund in our Payment Gateways (E.g
                            PayPal, Stripe other) since the chargeback fee will be too high to manage and it's possible to
                            solve the issue via our Refund Policy by ourself (You as Customer and Us as Service
                            Provider). You also cannot chargeback or ask for refund in our Payment Gateways if
                            you’ve received promised service. Any chargeback or Payment Gateways refund will
                            terminate your view4view account and your services, you will no longer be able to
                            recover your account or services unless You’ll pay us the chargeback fee + Service paid
                            amount + 10% Fee due to Chargeback situation.</p>
                        <h4>Cancelling the service</h4>
                        <p>By cancelling your service, you agree to lose everything you have purchased on our site.</p>
                        <h4>Refund Policy</h4>
                        <p>You can request a complete refund within 24h. However, you might need to provide valid reason
                            on why you want to refund and few of our services</p>
                        <br />
                        <h2>3. COOKIES POLICY</h2>
                        <br />
                        <h4>Do we use cookies?</h4>
                        <p>Yes, we use cookies to make better customer experience, ability to provide services and
                            improve the website</p>
                        <h4>How to delete the cookies?</h4>
                        <p>Please follow your browser tutorial on how to erase cookies.</p>
                        <h4>Google Analytics</h4>
                        <p>We use google analytics to get some statistics from our website. You agree to have their cookies and give few data to
                            them.</p>
                        <br />
                        <h2>4. Intellectual property</h2>
                        <p>The br/ands, logos, signs as well as all the contents of the site (texts, images, sound...) are
                            the subject of a protection by the Intellectual Property Code and more particularly by
                            copyright.</p>
                        <p>The User must request the prior authorization of the site for any reproduction, publication, copy of different contents.</p>
                        <p>He undertakes to use the contents of the site in a strictly private context, all use for commercial and advertising purposes is strictly prohibited.</p>
                        <p>Any total or partial representation of this site by any process whatsoever, without the express authorization of the operator of the website would constitute an infringement punishable by Article L 335-2 et seq. of the Intellectual Property Code.</p>
                        <h2>5. LEGALS MENTIONS</h2>
                        <br />
                        <p>Site Link: https://view4view.xyz</p>
                        <p>Site Owner(s): Leo Andert</p>
                        <p>Localisation: 9 rue du clos Poulain, 41350, Huisseau sur Cosson, France</p>
                        <p>Website host by: https://ovh.com/ </p>
                        <p>Contact: <a href="https://discord.gg/h39rPc6WQx">Discord Server</a></p>
                        <p>Siret: 89488500300018</p>
                    </article>
                </div>
            </body>
            <Footer />
        </>
    );
};

export default Legals;