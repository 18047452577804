import React from 'react';
import { Helmet } from 'react-helmet';
import logo from "../public/img/logov4v.png";

export default function Seo({ dynamic, title, description, image }) {
    if (dynamic) {
        return (
            <Helmet>
                { /* Standard metadata tags */}
                <title>{title}</title>
                <meta name="author" content={title} />
                <meta name='description' content={description} />
                <meta name="keywords" content="View4View" />

                { /* End standard metadata tags */}
                { /* Facebook tags */}
                <meta name="og:site_name" content="View4View" />
                <meta name="og:url" content="location" />
                <meta property="og:image" content={image ? image : logo} />
                <meta property="og:type" content={"website"} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                { /* End Facebook tags */}
                { /* Twitter tags */}
                <meta name="twitter:creator" content={"@View4View.png"} />
                <meta name="twitter:card" content={"website"} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                { /* End Twitter tags */}
                <meta name="apple-mobile-web-app-title" content={title} />
                <meta name="application-name" content={description} />
            </Helmet>
        )
    } else {
        return (
            <Helmet>
                { /* Standard metadata tags */}
                <title>View4View • Get views on Youtube and grow your channel for free</title>
                <meta name="author" content={"View4View • Get views on Youtube and grow your channel for free"} />
                <meta name='description' content={`Gain thousand of views very fast and organically  on your youtube videos with this amazing free to use website. Free and safe, our website shares your videos with people like you who want to grow and gain publicity their youtube channels and are serious about growing!`} />
                <meta name="keywords" content="Join4Join, Buy discord members, Discord Members, Get Discord Members, grow discord server" />
                { /* End standard metadata tags */}
                { /* Facebook tags */}
                <meta name="og:site_name" content="View4View" />
                <meta name="og:url" content="location" />
                <meta property="og:image" content={logo} />
                <meta property="og:type" content={"website"} />
                <meta property="og:title" content={`View4View • Get views on Youtube and grow your channel for free`} />
                <meta property="og:description" content={`Gain thousand of views very fast and organically  on your youtube videos with this amazing free to use  website. Free and safe, our website shares your videos with people like you who want to grow and gain publicity their youtube channels and are serious about growing!`} />
                { /* End Facebook tags */}
                { /* Twitter tags */}
                <meta name="twitter:creator" content={"@View4View"} />
                <meta name="twitter:card" content={"website"} />
                <meta name="twitter:title" content={`View4View • Get views on Youtube and grow your channel for free`} />
                <meta name="twitter:description" content={`Gain thousand of views very fast and organically  on your youtube videos with this amazing free to use  website. Free and safe, our website shares your videos with people like you who want to grow and gain publicity their youtube channels and are serious about growing!`} />
                { /* End Twitter tags */}
                <meta name="apple-mobile-web-app-title" content="View4View • Get views on Youtube and grow your channel for free" />
                <meta name="application-name" content="Gain thousand of views very fast and organically  on your youtube videos with this amazing free to use  website. Free and safe, our website shares your videos with people like you who want to grow and gain publicity their youtube channels and are serious about growing!" />
            </Helmet>
        )
    }
}