import Footer from "../layout/footer";
import Nav from "../layout/nav";
import { useState, useEffect } from "react";
import Loading from "../utils/loading";
import Seo from "../utils/Seo";
import getCampaignList from "../api/methods/getCampaignList";
import { Link } from "react-router-dom";

const Campaign = () => {

    const [campaign, setCampaign] = useState([]);
    const [load, setLoad] = useState(undefined);

    const get_campaign_list = async () => {
        const request = await getCampaignList();
        if (request && request.success === true) {
            setCampaign(request.data)
            setLoad(false)
        }
    }

    useEffect(() => {
        setLoad(true);
        get_campaign_list();
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <body>
                <div className="container">
                    <div className="container">
                        <h1 className="titlemain">MANAGE YOUR CAMPAIGNS</h1>
                        <div className="container_tips">
                            <div className="position_tips"><i className="fa-solid fa-circle-info"></i><p>Click on the thumbnail to manage your campaign</p></div>
                        </div>
                    </div>
                    {load ? <Loading /> :
                        <>
                            <div className="container wrap" style={{ flexDirection: "column" }}>
                                <div className="video_list">
                                    {campaign.length > 0 ? campaign.map((campaign_data) => {
                                        return (
                                            <>
                                                <Link to={"/promote/" + campaign_data.id}><img src={campaign_data.thumbnail} /></Link>
                                            </>
                                        )
                                    }) :
                                        <>
                                            <div>
                                                <h1>You don't have any campaign(s).</h1>
                                                <Link to={"/promote"}><button className="button button1">Start one here</button></Link>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </body>
            <Footer />
        </>
    );
};

export default Campaign;