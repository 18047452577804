import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './pages';
import History from './pages/history';
import Legals from './pages/legals';
import Premium from './pages/premium';
import reactGa from "react-ga";
import Campaign from './pages/campaign';
import Discover from './pages/discover';
import Promote from './pages/promote';
import Promote_ID from './pages/promote_id';
import Error from './pages/error';
import Privacy_Policy from './pages/privacy_policy';
import Login from './pages/login';


reactGa.initialize("G-V5ZZMPK3SV")
reactGa.pageview(window.location.pathname);

function App() {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://adsreference.com/widget.js?id=1706824063444964000"
    document.body.appendChild(script)
  }, []);

  return (
    <div className="root ">
      <div className="g-recaptcha" data-sitekey="6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E" data-size="invisible"></div>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/legals" element={<Legals />} />
          <Route path="/privacy" element={<Privacy_Policy />} />
          <Route path="/promote" element={<Promote />} />
          <Route path="/promote/:id" element={<Promote_ID />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/history" element={<History />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/login" element={<Login />} />
          <Route path="/error" element={<Error />} />

          <Route
            path="/close"
            element={
              <>
                {() => {
                  window.close();
                }}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;