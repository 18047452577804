import Footer from "../layout/footer";
import Nav from "../layout/nav";
import Seo from "../utils/Seo";

const Login = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <div className="login_position">
                <div className="container background login">
                    <div className="login_align">
                        <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fpngimg.com%2Fuploads%2Fgoogle%2Fgoogle_PNG19630.png&f=1&nofb=1&ipt=cde4ec4a5ea04f0ccda4e957d4a94702090beee4b703f5c06c243f71c79fa61c&ipo=images" />
                        <a href={"/login/google"}><button className="button button1">Login With Google</button></a>
                    </div>
                    <div className="login_align">
                        <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.logolynx.com%2Fimages%2Flogolynx%2F1b%2F1bcc0f0aefe71b2c8ce66ffe8645d365.png&f=1&nofb=1&ipt=4fd0b547c799f092a3f36de4714c4633ea71ece1f70861c668f52b0c3378f3e1&ipo=images" />
                        <a href={"/login/discord"}><button className="button button1">Login With Discord</button></a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;