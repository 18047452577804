import Footer from "../layout/footer";
import Nav from "../layout/nav";
import { useState, useEffect } from "react";
import Loading from "../utils/loading";
import Seo from "../utils/Seo";
import checkVideo from "../api/methods/checkVideo";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import setType from "../api/methods/setType";

const MySwal = withReactContent(Swal)

const Promote = () => {
    const navigate = useNavigate();

    const check_url = async () => {
        const url = document.getElementById("video_url")?.value
        if (url) {
            var id = url.match(/(?:v=)([^&]+)/) ? url.match(/(?:v=)([^&]+)/)[1] : undefined
            if (!id) id = url.match(/youtu\.be\/([^?]+)/) ? url.match(/youtu\.be\/([^?]+)/)[1] : undefined
            if (!id) id = url.match(/\/shorts\/([a-zA-Z0-9_-]{11})/) ? url.match(/\/shorts\/([a-zA-Z0-9_-]{11})/)[1] : undefined

            const request = await checkVideo(id);
            if (request && request.success === true) {
                navigate("/promote/" + id);
                const short = url.match(/\/shorts\/([a-zA-Z0-9_-]{11})/) ? url.match(/\/shorts\/([a-zA-Z0-9_-]{11})/)[1] : undefined
                if (short) {
                    await setType(short, "short");
                }
            } else {
                MySwal.fire({
                    title: 'Error',
                    html: <>
                        <p>Video Not found on youtube.</p>
                    </>,
                    footer: '<a href="https://discord.gg/h39rPc6WQx">Support Server</a>',
                    timer: 3000,
                    icon: "error"
                })
            }
        }
    }

    useEffect(() => {
        check_url();
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <body>
                <div className="container">
                    <div className="container">
                        <h1 className="titlemain">PROMOTE YOUTUBE VIDEOS</h1>
                        <div className="container_tips">
                            <div className="position_tips"><i className="fa-solid fa-circle-info"></i><p>Here is the page to promote youtube videos!</p></div>
                            <div className="position_tips"><i className="fa-solid fa-circle-info"></i><p>Put an url to start the campaign!</p></div>
                        </div>
                    </div>
                    <div className="container background">
                        <h1>URL OF THE VIDEO TO PROMOTE:</h1>
                        <input id="video_url" type="text" placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ" required />
                        <a><button class="button button1" onClick={() => { check_url(); }}>Promote</button></a>
                    </div>
                </div>
            </body>
            <Footer />
        </>
    );
};

export default Promote;