import Footer from "../layout/footer";
import Nav from "../layout/nav";
import { useState, useEffect } from "react";
import Loading from "../utils/loading";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, useNavigate } from "react-router-dom";
import Seo from "../utils/Seo";
import checkVideo from "../api/methods/checkVideo";
import getUserData from "../api/methods/getUserData";
import purchaseCampaign from "../api/methods/purchaseCampaign";
import getVideoData from "../api/methods/getVideoData";
import coinsEffect from "../utils/coinsEffect";
const MySwal = withReactContent(Swal)

const Promote_ID = () => {
    const [load, setLoad] = useState(undefined);
    const [thumbnail, setThumbnail] = useState(undefined);
    const [error_view, setErrorView] = useState(undefined);
    const [info_view, setInfoView] = useState(undefined);
    const [video_data, setVideo_data] = useState([]);
    const [error_watchtime, setErrorWatchtime] = useState(undefined);
    const [info_watchtime, setInfoWatchtime] = useState(undefined);
    const [coins, setCoins] = useState(undefined);
    const [view_amount, setViewAmount] = useState(undefined);
    const [view_watchtime, setViewWatchTime] = useState(undefined);

    const navigate = useNavigate();

    const user_data = async () => {
        const user = await getUserData();
        if (user && user.success === true) {
            setCoins(user.data?.money);
        }
    }

    const video_info = async () => {
        const request = await getVideoData(window.location.pathname.slice(9));
        if (request && request.success === true) {
            setVideo_data(request.data)
            setLoad(false);
        }
    }

    const check_url = async () => {
        const id = window.location.pathname.slice(9)
        const request = await checkVideo(id);
        if (request && request.success === true) {
            const thumbnail = request.data.items[0].snippet.thumbnails.high.url
            setThumbnail(thumbnail)
        } else {
            navigate("/promote");
        }
    }

    const checkView = () => {
        const check = document.getElementById("view");
        if (check && check.value) {
            setViewAmount(check.value);
            if (check.value > coins) {
                setInfoView(undefined);
                setErrorView("You don't have enough coins.")
            } else {
                if (typeof (Number(check.value)) === "number" && Number(check.value) !== 0 && Number(check.value) >= 5 && Number(check.value) <= 10000) {
                    setErrorView(undefined)
                    setInfoView(`Your video'll receive ${check.value} views.`)
                } else {
                    setInfoView(undefined)
                    setErrorView("You need to provide a number between 5-10000.")
                }
            }
        }
    }

    const checkWatchtime = () => {
        const check = document.getElementById("watchtime");
        if (check && check.value) {
            if (view_amount && (Number(view_amount) * Number(check.value)) > coins) {
                setInfoWatchtime(undefined);
                setErrorWatchtime("You don't have enough coins.")
            } else {
                setViewWatchTime(check.value);
                if (typeof (Number(check.value)) === "number" && Number(check.value) !== 0 && Number(check.value) >= 1 && Number(check.value) <= 25) {
                    console.log(video_data.duration)
                    console.log(check.value * 60)
                    if (video_data.duration < Number(check.value) * 60 && Number(check.value) > 1) {
                        setInfoWatchtime(undefined)
                        setErrorWatchtime(`Your video duration is under ${check.value} minutes`)
                    } else {
                        setErrorWatchtime(undefined)
                        setInfoWatchtime(`Each user'll have to watch ${check.value} minute${Number(check.value) !== 1 ? "s" : ""} to claim their rewards.`)
                    }
                } else {
                    setInfoWatchtime(undefined)
                    setErrorWatchtime("You need to provide a number between 1-25")
                }
            }
        }
    }

    const purchase = async () => {
        const request = await purchaseCampaign(view_amount, window.location.pathname.slice(9), view_watchtime);
        if (request && request.success === true) {
            const request = await getVideoData(window.location.pathname.slice(9));
            if (request && request.success === true) {
                setVideo_data(request.data)
                setLoad(false);
                coinsEffect();
            }
        } else {
            MySwal.fire({
                title: 'Error',
                html: <>
                    <p>Something went wrong during the purchase process.</p>
                </>,
                footer: '<a href="https://discord.gg/h39rPc6WQx">Support Server</a>',
                timer: 3000,
                icon: "error"
            })
        }
    }

    useEffect(() => {
        check_url();
        user_data();
        video_info();
        setLoad(true);
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <Nav />
            <body>
                <div class="container">
                    <div class="container_title">
                        <h1 class="titlemain">PROMOTE A VIDEO</h1>
                        <div class="container_tips">
                            <div class="position_tips"><i class="fa-solid fa-circle-info"></i><p>Boost your youtubes video for free quickly and with a high user quality! No bots, only real humans.</p></div>
                            <div class="position_tips"><i class="fa-solid fa-circle-info"></i><p>1 view for 1 minute watch time for 1 coin.</p></div>
                        </div>
                    </div>

                    {load ? <Loading /> :
                        <>
                            <div class="container background">
                                {
                                    video_data && video_data.pub && video_data.pub === "no" ?
                                        <>
                                            <img src={thumbnail ? thumbnail : undefined} />
                                            <h1>AMOUNT OF DESIRED VIEWS:</h1>
                                            {error_view ? <span className="error_message">{error_view}</span> : undefined}
                                            {info_view ? <span className="info_message">{info_view}</span> : undefined}
                                            <input onInput={checkView} id="view" type="number" min={1} max={10000} placeholder="Example: 500 (500 views)" />
                                            {
                                                video_data.type === "normal" ?
                                                    <>
                                                        <h1>DURATION DESIRED FOR THE VIEWS:</h1>
                                                        {error_watchtime ? <span className="error_message">{error_watchtime}</span> : undefined}
                                                        {info_watchtime ? <span className="info_message">{info_watchtime}</span> : undefined}
                                                        <input onInput={checkWatchtime} id="watchtime" type="number" min={1} max={10000} placeholder="Example: 1 (1 minute watch time for each views)" />
                                                    </> : undefined
                                            }
                                            <a><button class="button button1" onClick={() => { purchase() }}>Start the campaign</button></a>
                                        </>
                                        :
                                        <>
                                            <img src={thumbnail ? thumbnail : undefined} />
                                            <h1>CAMPAIGN INFOS:</h1>
                                            <p>Your campaign is in progress please be patient ! Progress : {video_data ? Number(video_data.viewrequest) - Number(video_data.viewupdate) : undefined}/{video_data ? video_data.viewrequest : undefined}</p>
                                        </>
                                }
                            </div>
                        </>
                    }
                </div>
            </body>
            <Footer />
        </>
    );
};

export default Promote_ID;